import { Box, Button, useTheme } from "@mui/material";
import { FC } from "react";
import HomeLinks from "../../utils/HomeLinks";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-scroll";

interface TopNavProps {
  scrolling: boolean;
  isToggle: boolean;
}

export const TopNav: FC<TopNavProps> = ({ scrolling, isToggle }) => {
  const theme = useTheme();

  const teaxt = theme.palette.primary;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", textAlign: "center" },
        }}
      >
        {HomeLinks.map((page) => (
          <Link
            to={page.path}
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            key={page.text}
            style={{
              marginInline: 10,
              display: "block",
              fontWeight: "bold",
              color: scrolling || isToggle ? teaxt.dark : "#fff",
              fontSize: "1rem",
              cursor: "pointer",
              fontFamily: "Quicksand",
              marginBlock: "20px",
            }}
          >
            {page.text}
          </Link>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          mt: { xl: "0px", xs: "40px" },
        }}
      >
        <Button
          sx={{
            background: "primary",
            fontWeight: "bold",
            mr: "10px",
            color: scrolling || isToggle ? teaxt.dark : "#fff",
            fontSize: "1rem",
          }}
          endIcon={<SearchIcon />}
        >
          Search
        </Button>
        <Button
          sx={{
            color: "#fff",
            fontSize: "1rem",
            fontWeight: "bold",
            px: "20px",
            borderRadius: scrolling ? "20px" : "",
            boxShadow: scrolling ? "2px 2px 4px rgba(0, 0, 0, 0.2)" : null,
            bgcolor: scrolling || isToggle ? teaxt.dark : null,
          }}
        >
          Get Stated
        </Button>
      </Box>
    </>
  );
};
