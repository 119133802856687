import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TopNav } from "../Components/Menu/TopNav";
import VideoPlayer from "../Components/Video/videoHero";
import vid from "../assets/vid.mp4";
import { FeatureSection } from "../Components/FeatureSection/featureSection";
import PauseCircleFilledOutlinedIcon from "@mui/icons-material/PauseCircleFilledOutlined";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, animateScroll as scroll } from "react-scroll";
import Corevalue from "../Components/coreValue/corevalue";
import Aboutus from "../Components/VisionMission/Aboutus";
import Projects from "../Components/Projects/Projects";
import ContactUs from "../Components/Contactus/ContactUs";
import mobilehero from "../assets/mobilehero.jpg";

const Home = () => {
  const theme = useTheme();

  const teaxt = theme.palette.primary;

  const [isToggle, setIsToggle] = useState(false);

  const ToggleMobileMenu = () => {
    setIsToggle(!isToggle);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 15) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [videoButton, setVideoButton] = useState(false);

  const videoPlayButton = () => {
    setVideoButton(!videoButton);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Box sx={{ position: "relative" }}>
      {/* Add CSS animation keyframes */}
      <style>
        {`
          @keyframes scaleAnimation {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(1.1);
            }
          }
        `}
      </style>
      {scrolling ? (
        <Stack
          sx={{
            position: "fixed",
            background: teaxt.light,
            height: "45px",
            width: "45px",
            borderRadius: "50%",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            mb: "15px",
            color: "#fff",
            zIndex: 99,
            bottom: "10%",
            right: "2%",
            cursor: "pointer",
          }}
          className="icon"
          style={{
            animation: "scaleAnimation 1s infinite alternate", // Apply the animation
          }}
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon />
        </Stack>
      ) : null}
      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          background: scrolling || isToggle ? "#fff" : "none",
          px: { md: "130px" },
          boxShadow: scrolling ? "" : "0 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "background 0.3s",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            boxShadow: "none",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            sx={{ color: scrolling || isToggle ? teaxt.dark : "#fff" }}
          >
            Aceion
          </Typography>
          {isMobile ? (
            <Box>
              <Box
                onClick={ToggleMobileMenu}
                color={scrolling || isToggle ? teaxt.main : "#fff"}
              >
                {isToggle ? (
                  <CloseOutlinedIcon sx={{ fontWeight: "bold" }} />
                ) : (
                  <DehazeOutlinedIcon sx={{ fontWeight: "bold" }} />
                )}
              </Box>
              {isToggle ? (
                <Box
                  sx={{
                    display: { sm: "block", md: "none" },
                    position: "absolute",
                    left: "0%",
                  }}
                >
                  <Paper
                    sx={{
                      maxWidth: "100%",
                      width: width,
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      alignContent: "center",
                      justifyContent: "center",
                      pb: "30px",
                    }}
                  >
                    <TopNav scrolling={scrolling} isToggle={isToggle} />
                  </Paper>
                </Box>
              ) : (
                ""
              )}
            </Box>
          ) : null}

          {isMobile ? null : (
            <TopNav scrolling={scrolling} isToggle={isToggle} />
          )}
        </Toolbar>
      </AppBar>
      {/* AppBar */}
      <Box
        id="home"
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            position: { xs: "relative", xl: "absolute" },
            height: { xs: "50vh", xl: "100vh" },
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {isMobile ? (
            <Box
              position="absolute"
              zIndex={-990}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              height="50vh"
              width={width}
              overflow="hidden"
            >
              <img src={mobilehero} alt="" style={{ opacity: 0.5 }} />
            </Box>
          ) : null}
          <Typography
            color="#fff"
            sx={{
              textAlign: "center",
              fontSize: { xs: "2.5rem", xl: "4rem" },
              fontWeight: "bold",
              fontFamily: "Roboto",
              letterSpacing: "3px",
              textTransform: "uppercase",
            }}
          >
            Empowering Sustainable Success
          </Typography>
          <Typography
            color="#fff"
            sx={{
              fontSize: { xs: "1.3rem", xl: "2rem" },
              fontWeight: "bold",
              fontFamily: "Roboto",
            }}
          >
            Crafting visionary strategies that embrace industry shifts
          </Typography>
          <Button
            variant="outlined"
            sx={{ borderColor: "#fff", mt: "20px", color: "#fff" }}
          >
            Read More
          </Button>
        </Box>
      </Box>
      {isMobile ? null : (
        <Box sx={{ margin: 0, padding: 0, position: "relative" }}>
          <>
            <Box
              sx={{
                bgcolor: "#none",
                position: "absolute",
                bottom: "5%",
                right: "5%",
                zIndex: 999,
                cursor: "pointer",
              }}
              onClick={() => videoPlayButton()}
            >
              {videoButton ? (
                <PlayCircleFilledOutlinedIcon
                  sx={{ fontSize: "50px", color: "#fff" }}
                />
              ) : (
                <PauseCircleFilledOutlinedIcon
                  sx={{ fontSize: "50px", color: "#fff" }}
                />
              )}
            </Box>
            <VideoPlayer videoUrl={vid} videoState={videoButton} />
          </>
        </Box>
      )}

      <FeatureSection />
      <Corevalue />
      <Aboutus />
      {/* Latest project post */}
      <Projects />

      {/* Cntact Us  */}
      <ContactUs />

      {/* Footer section */}
      <Stack
        id="footer"
        pt="50px"
        sx={{
          // px: { xs: "60px" },
          display: "flex",
        }}
      >
        <Box
          sx={{
            mt: theme.spacing(4),
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" fontWeight="bold">
                  Aceion
                </Typography>
                <Typography variant="body2">
                  Aceion is a leading consulting firm that empowers
                  organizations with innovative solutions and strategic
                  insights, optimizing operations and integrating sustainable
                  practices across various sectors to drive global business
                  success and responsible development.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" fontWeight="bold">
                  Quick Links
                </Typography>
                <Link
                  to="aboutus"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  key="aboutus"
                  style={{ cursor: "pointer" }}
                >
                  <Typography>About us</Typography>
                </Link>
                <Link
                  to="contactus"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  key="contactus"
                  style={{ cursor: "pointer" }}
                >
                  <Typography>Contact us</Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" fontWeight="bold">
                  Contact
                </Typography>
                <Typography>Phone: +442033701446</Typography>
                <Typography>Email: contact@aceion.co.uk</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" align="center" pt="50px">
              © {new Date().getFullYear()} Aceion. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Stack>
    </Box>
  );
};

export default Home;
