import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface FeatureSectionProps {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  subtitle2?: string;
}

export const FeatureComp: FC<FeatureSectionProps> = ({
  icon,
  title,
  subtitle,
  subtitle2,
}) => {
  const theme = useTheme();
  const teaxt = theme.palette.primary;

  return (
    <>
      <Grid
        item
        xs={12}
        md={2.5}
        bgcolor="#fff"
        sx={{
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          py: { md: "10px", xs: "20px" },
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: teaxt.dark, // Change the background color on hover
            "& .icon": {
              backgroundColor: "#fff",
              color: teaxt.dark, // Change icon color on hover
            },
            "& .title": {
              color: "#fff", // Change title color on hover
            },
            "& .subtitle": {
              color: "#fff", // Change subtitle color on hover
            },
            "& .subtitle2": {
              color: "#fff", // Change subtitle2 color on hover
            },
          },
        }}
      >
        <Box
          sx={{
            mr: "20px",
            gap: 2,
          }}
        >
          <Stack
            sx={{
              background: teaxt.dark,
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              mb: "15px",
              color: "#fff",
            }}
            className="icon"
          >
            {icon}
          </Stack>
          <Typography className="title" fontWeight="bold">
            {title}
          </Typography>
        </Box>

        <Typography className="subtitle" variant="body2" sx={{ pr: "20px" }}>
          {subtitle}
        </Typography>
        <Typography className="subtitle2" variant="body2" sx={{ pr: "20px" }}>
          {subtitle2}
        </Typography>
      </Grid>
    </>
  );
};
