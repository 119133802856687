import "@fontsource/quicksand";
import { ThemeProvider, createTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesComponents from "./routes/RoutesComponents";

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand",
  },
  palette: {
    primary: blue,
  },
});
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <RoutesComponents />
      </Router>
    </ThemeProvider>
  );
};

export default App;
