import React from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/system";

const StyledVideoContainer = styled("div")({
  position: "relative",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  
});

const StyledOverlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust the alpha value for transparency
});

const StyledPlayer = styled(ReactPlayer)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

interface VideoPlayerProps {
  videoUrl: string;
  videoState?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, videoState }) => {
  const handleError = (error: Error) => {
    console.error("Error loading the video:", error);
  };

  return (
    <>
      <StyledVideoContainer>
        <StyledPlayer
          url={videoUrl}
          playing={!videoState}
          loop
          muted
          onError={handleError}
          width="100%"
          height="125%"
        />
        <StyledOverlay />
       
      </StyledVideoContainer>
    </>
  );
};

export default VideoPlayer;
