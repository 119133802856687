import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

interface LatestCardProps {
  image: string;
  text: string;
  projectTitle: string;
}

const LatestCard: FC<LatestCardProps> = ({ image, text, projectTitle }) => {
  const maxLength = 75;
  return (
    <>
      <Box
        sx={{
          height: "280px",
          width: "300px",
          bgcolor: "#fff",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: "60%" }}>
          <img
            src={image}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ px: "10px", mt: "5px" }} gap={3}>
          <Typography fontWeight="bold">{projectTitle}</Typography>
          <Typography variant="body2" justifyContent={"justify"} py="5px">
            {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
          </Typography>
          <Typography
            fontWeight="bold"
            color="primary"
            sx={{ cursor: "pointer" }}
          >
            Learn more
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LatestCard;
