import latest1 from "../../assets/1.jpg";
import latest2 from "../../assets/2.jpg";
import latest3 from "../../assets/3.webp";
import latest4 from "../../assets/4.webp";
import latest5 from "../../assets/5.jpg";
import latest6 from "../../assets/6.png";
import latest7 from "../../assets/7.jpg";
import latest8 from "../../assets/8.jpg";
import latest9 from "../../assets/9.jpg";

export const LatestItems = [
  {
    projectTitle: "Startegic Planning",
    image: latest1,
    text: "In the quiet corners of my mind, dreams dance like fireflies on a summer night.",
  },
  {
    projectTitle: "Risk Management",
    image: latest3,
    text: "Lost in a labyrinth of thoughts, I seek solace in the whispers of the wind.",
  },
  {
    projectTitle: "Sector-Specific Solutions",
    image: latest5,
    text: "With each step, the path unfolds before me, revealing the mysteries of the universe.",
  },
  {
    projectTitle: "Operational Optimization",
    image: latest7,
    text: "Through the lens of imagination, ordinary moments become extraordinary adventures.",
  },
  {
    projectTitle: "Strategies Optimization",
    image: latest2,
    text: "In the symphony of life, every note carries the promise of a new beginning.",
  },
  {
    projectTitle: "Ancient Wisdom",
    image: latest4,
    text: "Beneath the veil of darkness, stars weave tales of ancient wisdom.",
  },
  {
    projectTitle: "Shines Bright",
    image: latest6,
    text: "Amidst the chaos of the world, a glimmer of hope shines bright.",
  },
  {
    projectTitle: "Rhythm of Existence",
    image: latest8,
    text: "With every heartbeat, I embrace the rhythm of existence.",
  },
  {
    projectTitle: "Silence of Solitude",
    image: latest9,
    text: "In the silence of solitude, I find the courage to be myself.",
  },
];
