const HomeLinks = [
  {
    text: "Home",
    path: "home",
  },
  {
    text: "Service",
    path: "services",
  },
  {
    text: "Features",
    path: "features",
  },
  {
    text: "About Us",
    path: "aboutus",
  },
  {
    text: "Projects",
    path: "projects",
  },
  {
    text: "Contact Us",
    path: "contactus",
  },
];

export default HomeLinks;
