import { Stack } from "@mui/material";
import React from "react";
import { TargetCard } from "./TargetCard";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import vision from "../../assets/vision.png";
const Aboutus = () => {
  return (
    <>
      <Stack
        id="aboutus"
        sx={{
          px: { xs: "20px", xl: "60px" },
          display: "flex",
        }}
      >
        <TargetCard
          head="Mission"
          icon={<Diversity2OutlinedIcon sx={{ fontSize: "15px" }} />}
          text="At Aceion, our mission is to empower organizations across all
                major sectors with innovative solutions, strategic insights, and
                transformative consulting services. We are dedicated to helping
                our clients thrive in an ever-evolving business landscape by
                providing tailored expertise that drives sustainable growth and
                success. With a deep commitment to sustainability, we actively
                contribute to positive change across diverse industries,
                fostering a future where organizations excel while prioritizing
                environmental responsibility."
          image={vision}
        />
        <TargetCard
          head="Vision"
          icon={<Diversity2OutlinedIcon sx={{ fontSize: "15px" }} />}
          text="Our vision is to be the foremost partner in driving global
          business success and sustainable development. We aspire to
          achieve this by delivering unparalleled consulting expertise,
          fostering a culture of innovation, and positively impacting
          communities through responsible and sustainable practices. At
          Aceion, we envision a future where organizations not only
          achieve financial success but also play a pivotal role in
          creating a better world for current and future generations."
          image={vision}
          rtl
        />
      </Stack>
    </>
  );
};

export default Aboutus;
