import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { FeatureComp } from "./featureComp";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";

export const FeatureSection = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        id="services"
        pt="30px"
        sx={{
          px: { xs: "40px", xl: "60px" },
        }}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          textAlign="center"
          sx={{
            fontFamily: "Roboto",
            my: "20px",
            color: theme.palette.primary.dark,
          }}
        >
          What We Do
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          px={{ xs: "", xl: "250px" }}
          fontWeight="bold"
        >
          {" "}
          At Aceion, we specialize in comprehensive consulting services across
          all major sectors. Our expert team collaborates closely with
          organizations to deliver solutions in the following key areas:
        </Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          gap={2}
          justifyContent="center"
          py={5}
        >
          <FeatureComp
            icon={<AdminPanelSettingsOutlinedIcon />}
            title="Strategic Planning"
            subtitle="Developing forward-thinking strategies that align with industry trends and sustainability goals."
            subtitle2="Assisting in the integration of sustainable practices into overall business strategies."
          />
          <FeatureComp
            icon={<LocalPoliceOutlinedIcon />}
            title="Risk Management and Compliance"
            subtitle="Identifying and mitigating risks associated with business operations and ensuring compliance with industry regulations and sustainability standards."
          />
          <FeatureComp
            icon={<AdsClickOutlinedIcon />}
            title="Sector-Specific Solutions"
            subtitle="Tailoring consulting services to address sector-specific challenges and opportunities, from technology to healthcare, finance to manufacturing."
          />
          <FeatureComp
            icon={<ManageHistoryOutlinedIcon />}
            title="Operational Optimization"
            subtitle="Implementing measures to enhance operational efficiency, reduce costs, and improve sustainability."
            subtitle2="Advising on sustainable technologies and practices for improved resource utilization."
          />
        </Grid>
      </Stack>
    </>
  );
};
