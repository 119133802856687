import { Typography, TextField, useTheme, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React from "react";

const ContactUs = () => {
  const theme = useTheme();

  const teaxt = theme.palette.primary;
  return (
    <>
      <Stack
        id="contactus"
        py="30px"
        sx={{
          px: { xs: "20px", xl: "60px" },
          display: "flex",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          textAlign="center"
          sx={{
            fontFamily: "Roboto",
            mb: "20px",
            color: theme.palette.primary.dark,
          }}
        >
          Contact Us
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", xl: "row" },
            gap: 5,
          }}
        >
          <Box sx={{ dislay: "flex", flexDirection: "column", gap: 4 }}>
            <Box
              sx={{
                borderRadius: "20px",
                ml: "10px",
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6304101319984!2d3.5100901744800495!3d6.441483224112078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf688fa295555%3A0x6dee73bd773030f7!2sShoprite!5e0!3m2!1sen!2sng!4v1708173650517!5m2!1sen!2sng"
                width="600"
                height="270"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>

            <Box
              sx={{
                borderRadius: "20px",
                ml: "10px",
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                mt:'20px'
              }}
            >
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6304101319984!2d3.5100901744800495!3d6.441483224112078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf688fa295555%3A0x6dee73bd773030f7!2sShoprite!5e0!3m2!1sen!2sng!4v1708173650517!5m2!1sen!2sng"
                width="600"
                height="270"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography sx={{ mx: { xl: "50px" } }}>
              We'd love to hear from you! Drop us a message below and we'll get
              back to you as soon as possible. Your feedback is valuable to us!
            </Typography>
            <Box sx={{ mt: "30px", mx: { xl: "25px" } }}>
              <TextField
                id="subject"
                label="Subject"
                type="subject"
                fullWidth
                size="small"
                sx={{ mb: "30px" }}
              />
              <TextField
                id="fullname"
                label="Full Name"
                type="fullname"
                fullWidth
                size="small"
                sx={{ mb: "30px" }}
              />
              <TextField
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                size="small"
                sx={{ mb: "30px" }}
              />
              <TextField
                id="message"
                label="Message"
                multiline
                rows={5} // You can adjust the number of rows as needed
                variant="outlined"
                fullWidth
                sx={{ mb: "30px" }}
              />
              <Button
                variant="contained"
                sx={{ bgcolor: teaxt.dark }}
                fullWidth
              >
                Send Message
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default ContactUs;
