import { Typography, Grid, useTheme } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React, { useRef } from "react";
import LatestCard from "../lastestProject/LatestCard";
import { LatestItems } from "../lastestProject/LatestItems";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const Projects = () => {

    const theme = useTheme();

    const teaxt = theme.palette.primary;

    const containerRef = useRef<HTMLDivElement>(null);
    const sliderButton = (side: string) => {
      if (containerRef.current && side === "left") {
        containerRef.current.scrollLeft -= 500;
      }
  
      if (containerRef.current && side === "right") {
        containerRef.current.scrollLeft += 500;
      }
    };
  return (
    <>
      <Stack
        id="projects"
        py="30px"
        sx={{
          px: { xs: "20px", xl: "60px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            fontWeight="bold"
            textAlign="center"
            sx={{
              fontFamily: "Roboto",
              my: "20px",
              color: theme.palette.primary.dark,
            }}
          >
            Latest Projects
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
            }}
          >
            <Stack
              sx={{
                background: teaxt.dark,
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                mb: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
              className="icon"
              onClick={() => sliderButton("left")}
            >
              <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "15px" }} />
            </Stack>
            <Stack
              sx={{
                background: teaxt.dark,
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                mb: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
              className="icon"
              onClick={() => sliderButton("right")}
            >
              <ArrowForwardIosOutlinedIcon sx={{ fontSize: "15px" }} />
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            height: "300px",
            overflowX: "scroll",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            mb: "20px",
          }}
          ref={containerRef}
        >
          <Box
            sx={{
              height: "100%",
              width: "140%",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            {" "}
            <Grid
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              gap={2}
              justifyContent="center"
              sx={{ display: "flex" }}
            >
              {LatestItems.map((slider, index) => {
                return (
                  <LatestCard
                    projectTitle={slider.projectTitle}
                    image={slider.image}
                    text={slider.text}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Projects;
