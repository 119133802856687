import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { FC } from "react";

interface TargetCardProps {
  icon: JSX.Element;
  text: string;
  image: string;
  rtl?: boolean;
  head: string;
}

export const TargetCard: FC<TargetCardProps> = ({
  icon,
  text,
  image,
  rtl,
  head,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const teaxt = theme.palette.primary;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: rtl ? "row-reverse" : "row",
          justifyContent: { xs: "center", xl: "space-between" },
          alignItems: "center",
          px: { xl: "60px" },
          mb: { xs: "20px", xl: "0px" },
        }}
      >
        <Box sx={{}}>
          <Box
            sx={{
              // mr: "20px",
              gap: 2,
            }}
          >
            <Stack
              sx={{
                background: teaxt.light,
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                mb: "15px",
                color: "#fff",
              }}
              className="icon"
            >
              {icon}
            </Stack>
            <Typography className="title" fontWeight="bold" variant="h6">
              {head}
            </Typography>
          </Box>
          <Box sx={{ width: "500px" }}>
            {" "}
            <Typography textAlign="justify">{text}</Typography>
          </Box>
        </Box>
        {isMobile ? null : (
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <img
              src={image}
              alt=""
              width={"100%"}
              style={{ marginLeft: "auto" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
