import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import ani from "../../assets/ani.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Corevalue = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <Stack
        id="features"
        py="30px"
        sx={{
          px: { xs: "20px", xl: "60px" },
        }}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          textAlign="center"
          sx={{
            fontFamily: "Roboto",
            my: "20px",
            color: theme.palette.primary.dark,
          }}
        >
          Core Values
        </Typography>

        {isMobile ? (
          <Typography
            variant="body2"
            textAlign="center"
           
            fontWeight="bold"
          >
            {" "}
            At Aceion, we are guided by the following core values
          </Typography>
        ) : null}

        <Grid container spacing={2} py={5}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: theme.palette.primary.dark,
                height: "350px",
                borderRadius: "0",
                borderTopRightRadius: "200px",
                borderBottomRightRadius: "200px",
                overflow: "hidden",
                justifyContent: "center",
                alignContent: "center",
                flexWrap: "wrap",
                display: "flex",
                position: "relative", // Set position to relative for absolute positioning of the image
              }}
            >
              <img
                src={ani}
                alt="alt"
                width="70%"
                style={{
                  animation: "scaleAnimation 1s infinite alternate", // Apply the animation
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} mt={{xs: '20px', xl: '0px'}}>
            {isMobile ? null : (
              <Typography
                variant="body2"
                textAlign="center"
                mb="20px"
                fontWeight="bold"
              >
                {" "}
                At Aceion, we are guided by the following core values
              </Typography>
            )}
            <Box>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography fontWeight="bold" variant="body1">
                    Excellence
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Striving for excellence in every aspect of our work,
                    delivering high-quality solutions that surpass client
                    expectations across diverse sectors.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography fontWeight="bold" variant="body1">
                    Integrity
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Conducting business with the highest ethical standards,
                    fostering trust and transparency in all our interactions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography fontWeight="bold" variant="body1">
                    Innovation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Embracing creativity and innovation, constantly seeking new
                    and effective ways to add value to our clients and promote
                    sustainable practices.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography fontWeight="bold" variant="body1">
                    Collaboration
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Believing in the power of collaboration, we work closely
                    with our clients across various sectors, recognizing that
                    shared success is the key to sustained growth and positive
                    impact.
                  </Typography>
                  <Typography>
                    Our commitment to these values forms the foundation of our
                    approach, ensuring that every engagement with Aceion not
                    only drives business success but also contributes positively
                    to environmental sustainability and the well-being of
                    communities worldwide.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default Corevalue;
